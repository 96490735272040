import React from "react"
import SEO from "../components/seo"
import "../bootstrap.min.css"

import { H1, H2, H3, P, PSmall } from "../components/text"
import Button from "../components/button"
import Section from "../components/section"
import Footer from "../components/footer"

import Header from "../components/header"
import Hero from "../components/hero"

import imagePanier from "../images/panier.jpg"
import imageDecoNoel from "../images/deco-noel.jpg"
import imagePetitsPanettones from "../images/petits-panettones.jpg"


const Page = () => (
  <div>
    <SEO title="Raineri" />
    <Header/>

    <Hero title="Menu des fêtes" subtitle="Découvrez notre carte des Fêtes de fin d'année" image={imageDecoNoel} menuFetes={false}/>

    {/* <H1 className="text-center">Menu des fêtes</H1> */}

    <br/>
    <br/>

    <Section className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <H3 className="text-center">
            <p><strong>Pour Noël :</strong> réservation pour le 20/12 au plus tard</p>
            <p><strong>Pour le Nouvel an :</strong> réservation pour le 27/12 au plus tard</p>
            <p><strong>Commandez par téléphone au</strong> 081/61.46.10</p>
          </H3>
        </div>
      </div>
    </Section>

    <Section className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          {/* <H3>Entrées</H3>
          <PSmall className="font-weight-bold mb-1">Ravioles d’Agnello au thym, speck et crème</PSmall>
          <PSmall className="text-muted mb-4">19,20 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Raviole aux truffes</PSmall>
          <PSmall className="text-muted mb-4">17,50 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Panciotti aux St Jacques, crème de tomates cerises, crevettes grises et Prosecco</PSmall>
          <PSmall className="text-muted mb-4">21,60 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Ravioles au homard, sauce bisque, courgettes et amandes grillées</PSmall>
          <PSmall className="text-muted mb-4">23,90 € / kg</PSmall>

          <br/>
          <br/> */}

          <H3>Entrées et/ou Plats</H3>
          <PSmall className="font-weight-bold mb-1">Fiocchi aux poires et fromage, sauce Gorgonzola et noix</PSmall>
          <PSmall className="text-muted mb-4">13,50 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Ravioles aux truffes et stracchino</PSmall>
          <PSmall className="text-muted mb-4">23,70 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Ravioles d’Agnello au thym, speck et crème</PSmall>
          <PSmall className="text-muted mb-4">19,20 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Panciotti aux St Jacques, crème de tomates cerises, crevettes grises et Prosecco</PSmall>
          <PSmall className="text-muted mb-4">21,60 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Ravioles au homard, sauce bisque, courgettes et amandes grillées</PSmall>
          <PSmall className="text-muted mb-4">23,90 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Cannelloni scampis/courgettes sauce bisque</PSmall>
          <PSmall className="text-muted mb-4">22,60 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Cannelloni aux 2 saumons et aneth</PSmall>
          <PSmall className="text-muted mb-4">24,40 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Cannelloni "viande de veau" sauce foie gras et truffes</PSmall>
          <PSmall className="text-muted mb-4">29,70 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Magret de canard (± 200 gr) aux champignons des bois, jus au romarin, grenailles rôties</PSmall>
          <PSmall className="text-muted mb-4">30,95 € / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Ravioles aux scampis, sauce pomodoro al pesto verde e pesto rosso</PSmall>
          <PSmall className="text-muted mb-4">29,40 € / kg</PSmall>

          <p className="mt-5"><strong>Appelez-nous au 081/61.46.10 pour commander</strong></p>
        </div>
      </div>

    </Section>

    <H2 style={{textTransform: "uppercase", textAlign: "center", paddingBottom: "50px", fontSize: "60px"}}>Pour offrir</H2>
    <Section className="container">
      <div className="row">
        <div className="col-md-6 mb-5">
          <H2>Paniers composés</H2>
          <P>Besoin d’une idée cadeau pour les fêtes ?</P>
          <P>Venez composer un panier selon vos envies et votre budget.</P>
          <P>Nous vous accompagnons dans votre choix ou nous réalisons des compositions sur mesure.</P>
          <br/>
          <Button href="/magasin">Prendre contact</Button>
        </div>
        <div className="col-md-6 mb-5">
          <img src={imagePanier} className="img-fluid"/>
        </div>
      </div>
    </Section>

    <H2 style={{textTransform: "uppercase", textAlign: "center", paddingBottom: "50px", fontSize: "60px"}}>Panettones</H2>

    <Section className="container">
      <div className="row">
        <div className="col-md-6 mb-5">
          <H2>Un dessert festif</H2>
          <P>Tradition italienne de Noël par excellence, le panettone est un dessert moelleux et délicieux.</P>
          <P>Nous avons plusieurs goûts de panettone en magasin.</P>
          <P>Venez les découvrir !</P>
          <br/>
          <Button href="/magasin">Voir magasin</Button>
        </div>
        <div className="col-md-6 mb-5">
          <img src={imagePetitsPanettones} className="img-fluid"/>
        </div>
      </div>
    </Section>

    <H2 style={{textTransform: "uppercase", textAlign: "center", paddingBottom: "50px", fontSize: "60px"}}>Joyeux Noël à tous !</H2>

    {/* <center>
      <iframe src="https://player.vimeo.com/video/487647233" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </center> */}

    <br/>
    <br/>

    <Footer/>
  </div>
)

export default Page
